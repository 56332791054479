export const transformISOtoText = (
  isoTime: string,
  minutesLabel: string,
  hourLabel: string,
  hoursLabel: string
): string | undefined | null => {
  const numberExtraction = (timeIso: string) => {
    const numberDetect = /\d+/;
    const number = timeIso.match(numberDetect);
    return `${number}`;
  };

  const minutePattern = /^PT\d{1,3}M$/;
  const hourPattern = /^PT\d{1,3}H$/;

  if (!isoTime) return null;

  if (minutePattern.test(isoTime)) {
    const minutes = numberExtraction(isoTime);
    return `${minutes} ${minutesLabel}`;
  }
  if (hourPattern.test(isoTime)) {
    const hour = numberExtraction(isoTime);
    if (parseInt(hour) > 1) return `${hour} ${hoursLabel}`;
    return `${hour} ${hourLabel}`;
  }
  if (isoTime.includes("/")) {
    const parts = isoTime.split("/");
    const leftPart = parts[0];
    const rightPart = parts[1];
    const rangeValueLeft = numberExtraction(leftPart);
    const rangeValueRight = numberExtraction(rightPart);

    if (leftPart.includes("M") && rightPart.includes("M"))
      return `${rangeValueLeft}-${rangeValueRight} ${minutesLabel}`;
    if (leftPart.includes("M") && rightPart.includes("H")) {
      if (parseInt(rangeValueRight) > 1)
        return `${rangeValueLeft} ${minutesLabel} - ${rangeValueRight} ${hoursLabel}`;
      return `${rangeValueLeft} ${minutesLabel} - ${rangeValueRight} ${hourLabel}`;
    }
    if (leftPart.includes("H") && rightPart.includes("H")) {
      return `${rangeValueLeft}-${rangeValueRight} ${hoursLabel}`;
    }
  }
};
