
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
  query {
    directus {
      recipe_tag {
        name
        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
          label
          languages_code {
            name
            code
          }
        }
      } 
    }
  }
`;

  export const useRecipeTagsStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  