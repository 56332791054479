import React, { useCallback } from "react";
import Page from "../components/Page";
import RecipeSearch from "../components/RecipeSearch";
import { RecipeSearchProps } from "../components/RecipeSearch/RecipeSearch";
import { getTranslations } from "../helpers/translations";
import { useIntl } from "../hooks/useIntl";
import { graphql } from "gatsby";
import SEO from "../components/SEO";
import { parseSEO } from "../helpers/seo";
import { useStaticLabels } from "../hooks/useStaticLabels";

const Basic = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();

  const getSeoData = useCallback(() => {
    return getTranslations(
      data,
      "directus.recipe_search_page.translations",
      intl.locale
    );
  }, [data]);

  const getData = (): RecipeSearchProps => {
    return {
      backLabel: labels?.backToAllRecipesLabel,
      title: labels?.searchResults,
      searchPlaceholder: labels?.search,
    } as RecipeSearchProps;
  };

  return (
    <Page
      headerBackgroundColor={"#033305"}
      helmet={<SEO {...parseSEO(getSeoData(), intl?.locale)} />}
    >
      <RecipeSearch {...getData()} />
    </Page>
  );
};

export default Basic;

export const query = graphql`
  query ($locale: String) {
    directus {
      recipe_search_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          id
          languages_code {
            name
            code
          }
        }
      }
    }
  }
`;
