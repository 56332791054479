
  import { graphql, useStaticQuery } from "gatsby";
  export const query = graphql`
  query {
    directus {
      recipe_page(limit: -1) {
        recipe_page_slug
        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
          languages_code {
            code
            name
          }
          status
          recipe_content_status
          recipe {
            date_created
            product_page {
              product_page_slug
              translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
                languages_code {
                  code
                  name
                }
                variants_status
                product(limit: 1) {
                  translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
                    languages_code {
                      code
                      name
                    }
                    product_name
                    variants {
                      product_variant_id {
                        variant_slug
                        translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
                          languages_code {
                            code
                            name
                          }
                          status
                          title
                          small_image_alt
                          small_image {
                            id
                            filename_disk
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
              languages_code {
                code
                name
              }
              status
              title
              author
              serves_value
              prep_time_value
              cook_time_value
              tags {
                recipe_tag_id {
                  name
                  translations(filter: {languages_code: {code:{_eq: "en-US"}}}) {
                    languages_code {
                      code
                      name
                    }
                    label
                  }
                }
              }
              image {
                id
                filename_disk
              }
              image_alt
              left_tab_content
              right_tab_content
              video_url
            }
          }
        }
      }
    }
  }
`;

  export const useRecipesStaticQuery = () => {
   const data = useStaticQuery(query);
   return data;
  }

  