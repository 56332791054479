import { uniqueId } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { StringParam, useQueryParam } from "use-query-params";
import { rem, vwDesktop, vwMobile } from "../../helpers/styles";
import { MEDIA_DESKTOP } from "../../helpers/theme";
import RecipeCard, { RecipeItem, RecipeItemColors } from "../shared/RecipeCard";
import SearchInput from "./TextInput";
import { BackButton } from "../shared/BackButton";
import { ROUTES } from "../../helpers/routes";
import { mapRecipeCard } from "../../helpers/recipe";
import { getTranslations } from "../../helpers/translations";
import useIntl from "../../hooks/useIntl";
import { Paginator } from "../shared/Paginator";
import { usePagination } from "react-use-pagination";
import { isPublished } from "../../helpers/directus";
import { useStaticLabels } from "../../hooks/useStaticLabels";
import { useRecipesStaticQuery } from "../../hooks/staticQueries/useRecipesStaticQuery";
import { useRecipeTagsStaticQuery } from "../../hooks/staticQueries/useRecipeTagsStaticQuery";

export type RecipeSearchProps = {
  backLabel: string;
  searchPlaceholder: string;
  title: string;
};

const colors: RecipeItemColors = {
  bgColor: "#012102",
  labelColor: "#75c154",
  textColor: "white",
  hoverBgColor: "#75c154",
  hoverTextColor: "black",
};

export function RecipeSearch(props: RecipeSearchProps): JSX.Element {
  const intl = useIntl();
  const labels = useStaticLabels();
  const data = useRecipesStaticQuery();
  const recipeTagsLabels = useRecipeTagsStaticQuery();
  const [allItems, setAllItems] = useState<RecipeItem[]>([]);

  const [inputValue, setInputValue] = useState("");
  const [q] = useQueryParam("q", StringParam);
  const itemId = uniqueId("recipe-");
  const [tags, setTags] = useState<string[]>([]);
  const [queryTags, setQueryTags] = useState<string[]>([]);
  const [textChange, setTextChange] = useState<boolean>(false);

  useEffect(() => {
    const recipeTags = recipeTagsLabels?.directus?.recipe_tag?.map(tag => tag.translations[0]?.label.toLowerCase());
    setTags(recipeTags);
  }, []);

  const filteredRecipes = useMemo(() => {
    return allItems
      ?.filter(
        (e) => {
          if (queryTags.length>0 && !textChange) {
            if (queryTags.every(item => tags.includes(item.toLowerCase()))) {
              const lowercaseArray = queryTags.map(element => element.toLowerCase());
              return e.tags
              ?.map((item) => item.recipe_tag_id.translations)
              .flat()
              .some(
                (translation) =>
                lowercaseArray.includes(translation.label?.toLowerCase()) &&
                  translation.languages_code.code === intl.locale
              )
            }
          }
          if(!inputValue) return true;
          if (tags?.includes(inputValue.toLowerCase())) {
            return e.tags
            ?.map((item) => item.recipe_tag_id.translations)
            .flat()
            .some(
              (translation) =>
                inputValue?.toLowerCase() === translation.label?.toLowerCase() &&
                translation.languages_code.code === intl.locale
            )
          }
          return e.title?.toLowerCase().includes(inputValue?.toLowerCase()) ||
              e.subtitle?.toLowerCase().includes(inputValue?.toLowerCase());
        }
      )
      ?.sort(
        (a, b) => new Date(b?.date).getTime() - new Date(a?.date).getTime()
      );
  }, [allItems, inputValue]);

  const {
    currentPage,
    totalPages,
    setPage,
    setNextPage,
    setPreviousPage,
    nextEnabled,
    previousEnabled,
    startIndex,
    endIndex,
  } = usePagination({
    totalItems: filteredRecipes?.length ?? 0,
    initialPageSize: 9,
  });

  const handlePageChange = useCallback(
    (active: number) => {
      setPage(active);
      handlePaginationClick();
    },
    [setPage]
  );

  const handlePaginationClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    });
  };

  const handleNextPage = useCallback(() => {
    setNextPage();
    handlePaginationClick();
  }, [setNextPage]);

  const handlePrevPage = useCallback(() => {
    setPreviousPage();
    handlePaginationClick();
  }, [setPreviousPage]);

  useEffect(() => {
    if (data) {
      const recipes = data?.directus?.recipe_page
        ?.map((recipe_page: any) => {
          const { recipe_content_status, recipe, status } =
            getTranslations(recipe_page, "translations", intl.locale) ?? {};

          const modifiedRecipe = {
            ...recipe,
            recipe_page_slug: recipe_page.recipe_page_slug,
          };

          return {
            modifiedRecipe,
            recipe_content_status,
            status,
          };
        })
        ?.filter(
          ({
            recipe_content_status,
            status,
          }: {
            recipe_content_status: string;
            status: string;
          }) => isPublished(recipe_content_status) && isPublished(status)
        );

      setAllItems(
        recipes?.map(({ modifiedRecipe }: { modifiedRecipe: any }) =>
          mapRecipeCard(modifiedRecipe, labels, intl.locale)
        )
      );
    }
  }, [data]);

  useEffect(() => {
    const searchString = "tags";
    const tagsSearch = q?.includes(searchString);
    if (tagsSearch) {
      const tempInput = q?.replace("tags-", "").split("-");
      setQueryTags(q?.replace("tags-", "").split("-"));
      setInputValue(tempInput?.join(", "));
    } else {
      setInputValue(q);
    }
  }, [q]);

  return (
    <Container>
      <form onSubmit={(e) => e.preventDefault()}>
        <BackButton link={ROUTES.RECIPES} label={props.backLabel} />
        <Title>
          <h1>{props.title}</h1>
        </Title>

        <InputContainer>
          <SearchInput
            value={inputValue}
            label={props.searchPlaceholder}
            onChange={(v) => {
              setInputValue(v as string);
              setTextChange(true);
            }}
            onClose={() => {
              setInputValue("");
              setTextChange(true);
            }}
          />
        </InputContainer>
      </form>

      <Results>
        {filteredRecipes?.slice(startIndex, endIndex + 1).map((result, i) => (
          <RecipeCard
            key={`${itemId}-${i}`}
            {...colors}
            content={result}
          />
        ))}
      </Results>
      <Paginator
        totalPages={totalPages}
        currentPage={currentPage + 1}
        onClick={handlePageChange}
        onNext={handleNextPage}
        onNextDisabled={!nextEnabled}
        onPrev={handlePrevPage}
        onPrevDisabled={!previousEnabled}
      />
    </Container>
  );
}

const Container = styled.div`
  background-color: #033305;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  padding-bottom: ${vwMobile(40)};
  ${MEDIA_DESKTOP} {
    padding-bottom: ${vwDesktop(80)};
  }
  form {
    padding: ${vwMobile(22)} ${vwMobile(24)};
    ${MEDIA_DESKTOP} {
      padding: ${vwDesktop(46)} ${vwDesktop(102)} ${vwDesktop(96)};
    }
  }
`;

const Title = styled.div`
  margin-bottom: ${vwMobile(23)};

  ${MEDIA_DESKTOP} {
    margin-bottom: ${vwDesktop(34)};
  }

  > * {
    font-size: ${rem(48)};
    line-height: ${rem(52)};
    letter-spacing: ${rem(1.44)};
    font-weight: 900;
    color: #75c154;
    text-transform: uppercase;

    ${MEDIA_DESKTOP} {
      font-size: ${rem(80)};
      line-height: ${rem(88)};
      text-align: center;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  > * {
    ${MEDIA_DESKTOP} {
      width: ${vwDesktop(610)};
    }
  }
`;

const Results = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${vwMobile(40)};

  ${MEDIA_DESKTOP} {
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${vwDesktop(20)};
    row-gap: ${vwDesktop(40)};
    justify-content: center;
  }
`;
