import React, { useRef, useState } from "react";
import InlineSVG from "react-inlinesvg/esm";
import styled from "styled-components";
import { nanoid } from "nanoid";
import {
  toREM,
  toVWDesktop,
  toVWMobile,
  vwDesktop,
  vwMobile,
} from "../../../helpers/styles";
import { BREAKPOINTS, MEDIA_DESKTOP } from "../../../helpers/theme";
import searchIcon from "../assets/searchIcon.svg";
import closeIcon from "../assets/closeIcon.svg";
import { useStaticLabels } from "../../../hooks/useStaticLabels";

export type InputProps = {
  label?: string;
  value?: string;
  type?: string;
  onClose: () => void;
  onChange: (value: string) => void;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export default function TextInput({
  label = "",
  value = "",
  onClose,
  onChange,
  ...props
}: InputProps): JSX.Element {
  const labels = useStaticLabels();
  const id = useRef<string>(nanoid());
  const [focused, setFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  function handleClose() {
    onClose();
    setFocused(false);
  }

  function handleLabelClick() {
    setFocused(true);
  }

  return (
    <Container onClick={() => inputRef.current?.focus()}>
      {label?.length > 0 && (
        <Label
          htmlFor={props?.id || id?.current}
          onClick={handleLabelClick}
          className={value?.length > 0 || focused ? "up" : ""}
        >
          {label}
        </Label>
      )}
      <input
        id={props?.id || id?.current}
        ref={inputRef}
        onChange={(e) => onChange(e.target.value)}
        value={value}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        {...props}
      />
      <Icon src={searchIcon} title={labels?.search} />
      <CloseButton
        aria-label={labels?.close}
        type="button"
        onClick={handleClose}
      >
        <CloseIcon src={closeIcon} title={labels?.close || ""} />
      </CloseButton>
    </Container>
  );
}

const CloseButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
  position: absolute;
  top: ${vwMobile(19)};
  right: ${vwMobile(17)};
  cursor: pointer;
  ${MEDIA_DESKTOP} {
    top: ${vwDesktop(19)};
    right: ${vwDesktop(17)};
  }
`;

const CloseIcon = styled(InlineSVG)`
  width: ${vwMobile(18)};
  height: ${vwMobile(18)};
  pointer-events: none;

  ${MEDIA_DESKTOP} {
    width: ${vwDesktop(18)};
    height: ${vwDesktop(18)};
  }
`;

const Icon = styled(InlineSVG)`
  position: absolute;
  top: ${vwMobile(16)};
  left: ${vwMobile(16)};
  width: ${vwMobile(20)};
  height: ${vwMobile(20)};

  ${MEDIA_DESKTOP} {
    top: ${vwDesktop(18)};
    left: ${vwDesktop(16)};
    width: ${vwDesktop(20)};
    height: ${vwDesktop(20)};
  }
`;

const Container = styled.div`
  display: flex;
  position: relative;

  width: 100%;
  min-width: ${toVWMobile(150)}vw;
  padding: ${toVWMobile(16)}vw ${toVWMobile(20)}vw;
  padding-inline: ${vwMobile(48)};

  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  background-color: #ffffff;
  border: ${toVWMobile(2)}vw solid #000000;

  ${MEDIA_DESKTOP} {
    padding: ${toVWDesktop(16)}vw ${toVWDesktop(20)}vw;
    padding-inline: ${vwDesktop(48)};
    min-width: ${toVWDesktop(150)}vw;
    border-width: ${toVWDesktop(2)}vw;
  }

  input {
    border: none;
    outline: none;
    width: 100%;
    flex: 1;
    align-self: flex-end;
    position: relative;
    bottom: -${toVWMobile(8)}vw;
    z-index: 1;
    color: "#000";
    background: #ffffff;

    font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
    font-size: ${toREM(16)}rem;
    font-weight: 700;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      bottom: -${toVWDesktop(8)}vw;
    }
  }
`;

const Label = styled.label`
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: ${toREM(16)}rem;
  color: #000000;
  font-weight: 700;
  z-index: 2;
  line-height: 1;
  transition: all 0.2s;

  &.up {
    font-size: ${toREM(12)}rem;
    transform: translateY(-150%);
  }
`;
